<template>
  <div class="collection-form white pa-5 py-3">
    <step-one
      v-if="step == 1"
      @next="nextHandler"
      @cancel="$emit('close')"
      :item="item"
      :isEdit="isEdit"
    ></step-one>
    <step-two
      v-if="step == 2"
      @submit="submitHandler"
      @cancel="$emit('close')"
      :item="item"
      :isEdit="isEdit"
    ></step-two>
  </div>
</template>

<script>
import StepOne from "./StepOne.vue";
import StepTwo from "./StepTwo.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    StepOne,
    StepTwo
  },
  data: () => ({
    step: 1,
    obj: {
      title: "",
      header: "",
      body: "",
      miniguides: [],
      villages: [],
      paragraphs: [
        {
          text: "text"
        }
      ],
      view: "",
      featured: false
    },
    imgFile: ""
  }),
  methods: {
    nextHandler(val) {
      this.imgFile = val.imgFile;
      this.obj = {
        title: val.title,
        body: val.body,
        header: val.header,
        type: "miniguide",
        miniguides: [],
        villages: []
      };
      this.step = 2;
    },
    async submitHandler(val) {
      val.forEach((select) => {
        this.obj["miniguides"].push(select._id);
      });
      this.obj.country = this.$root.country.short;
      if (this.isEdit) {
        let responseImage;
        if (this.imgFile) {
          // upload image
          let formData = new FormData();
          formData.append("collectionId", this.$route.params.id);
          formData.append("coverImage", this.imgFile);

          // request to upload image
          responseImage = await this.$axios.post(
            `media/collection/cover`,
            formData,
            {
              "content-type": "multipart/form-data"
            }
          );
        }
        if (responseImage) {
          this.obj.coverImage = responseImage.data.data.coverImage;
        }

        // request to send infos to collection
        let resEdit = await this.$axios.put(
          `collections/${this.$route.params.id}`,
          this.obj
        );
        if (resEdit.data) {
          this.$store.dispatch("showSnack", {
            text: "Edited succesfully",
            color: `success`
          });

          this.$emit("reloadData");
          this.$emit("close");
          setTimeout(() => {
            this.step = 1;
          }, 200);
        }
      } else {
        // request to create collection id
        let resCreate = await this.$axios.post(`collections/add/new`);
        if (resCreate.data) {
          let responseImage;
          if (this.imgFile) {
            // upload image
            let formData = new FormData();
            formData.append("collectionId", resCreate.data.data._id);
            formData.append("coverImage", this.imgFile);

            // request to upload image
            responseImage = await this.$axios.post(
              `media/collection/cover`,
              formData,
              {
                "content-type": "multipart/form-data"
              }
            );
          }
          if (responseImage) {
            this.obj.coverImage = responseImage.data.data.coverImage;
          }

          // request to send infos to collection
          let resEdit = await this.$axios.put(
            `collections/${resCreate.data.data._id}`,
            this.obj
          );
          if (resEdit.data) {
            this.$store.dispatch("showSnack", {
              text: "Added succesfully",
              color: `success`
            });

            this.$emit("reloadData");
            this.$emit("close");
            setTimeout(() => {
              this.step = 1;
            }, 200);
          }
        } else {
          this.$store.dispatch("showSnack", {
            text: "Something went wrong",
            color: "error"
          });
        }
      }
    }
  }
};
</script>

<style></style>
