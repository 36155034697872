<template>
  <div class="collection-form-step">
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <div class="collection-form-step-head py-3">
      <span class="black--text font-16 font-500"
        >1 of 2 – Add collection details</span
      >
    </div>

    <div class="collection-form-step-content py-5 px-5">
      <v-form v-model="valid" @submit.prevent="nextHandler">
        <v-row>
          <!-- upload image -->
          <v-col cols="12" class="py-1 pb-2">
            <div class="upload-image">
              <img :src="newImage ? newImage : stepObj.coverImage?.url" alt="" />
              <div class="upload-btn cursor-pointer">
                <v-icon dark color="grey">mdi-upload</v-icon>
                <span class="font-15 grey--text">Upload Image</span>
                <v-file-input
                  accept="image/*"
                  label="File input"
                  class="file-upload"
                  @change="newImageHandler"
                  hide-details="true"
                ></v-file-input>
              </div>
            </div>
          </v-col>
          <!-- name -->
          <v-col cols="12" md="12" class="py-1">
            <label class="grey--text font-12 font-500">COLLECTION NAME</label>
            <base-input placeholder="Name" v-model="stepObj.title[language]">
            </base-input>
          </v-col>

          <!-- header -->
          <v-col cols="12" md="12" class="pt-1 pb-0">
            <label class="grey--text font-12 font-500">HEADER</label>
            <base-textarea
              placeholder="Header"
              v-model="stepObj.header[language]"
              :rules="[(v) => !!v || '']"
            ></base-textarea>
          </v-col>
          <!-- description -->
          <v-col cols="12" md="12" class="pt-1 pb-0">
            <label class="grey--text font-12 font-500">DESCRIPTION</label>
            <base-textarea
              placeholder="Description"
              v-model="stepObj.body[language]"
              :rules="[(v) => !!v || '']"
            ></base-textarea>
          </v-col>
        </v-row>
        <!-- <div
          class="collection-form-step-btns d-flex align-center justify-space-between py-3 mt-8"
        >
          <v-btn elevation="1" @click="$emit('cancel')"
            ><span class="font-12">Cancel</span></v-btn
          >
          <v-btn
            elevation="1"
            color="darkblue"
            :dark="valid"
            type="submit"
            :disabled="!valid"
            ><span class="font-12">Next: Collection Details</span></v-btn
          >
        </div> -->
        <v-col cols="12" class="mt-14">
          <!-- buttons -->
          <buttons-experience
            @cancel="$emit('cancel')"
            @next="nextHandler"
            text="Next"
            :isValid="valid"
            :key="valid"
          ></buttons-experience>
        </v-col>
      </v-form>
    </div>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  components: {
    ButtonsExperience
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: false,
    stepObj: {
      title: {},
      header: {},
      description: {},
      body: {},
      coverImage: { url: "" }
    },
    newImage: "",
    language: "en"
  }),
  methods: {
    newImageHandler(file) {
      this.newImage = URL.createObjectURL(file);
      this.stepObj.imgFile = file;
    },
    nextHandler() {
      this.$emit("next", this.stepObj);
    }
  },
  async created() {
    this.language = this.$root.language;
    if (this.item) {
      this.stepObj = { ...this.item };
    }
  }
};
</script>

<style lang="scss">
.collection-form-step {
  .collection-form-step-head {
    border-bottom: 1px solid rgba($grayicon, 0.7);
  }
  .collection-form-step-btns {
    border-top: 1px solid rgba($grayicon, 0.7);
  }
}
</style>
