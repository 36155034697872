<template>
  <div class="collections-list transition-3s">
    <!-- head section -->
    <div class="pt-5 pb-2 px-2">
      <div class="d-flex w-100 justify-space-between">
        <span>
          <p class="gray--text mr-4 mt-1">Collections ({{ total }})</p>
        </span>
        <base-input
          placeholder="Search Collection"
          @input="collectionSearch"
          :rules="[]"
          clearable
        ></base-input>
      </div>
      <div class="mt-4" v-if="canAddCollection">
        <div
          class="py-2 text-center radius-5 secondary cursor-pointer"
          @click="$emit('addCollection')"
        >
          <span class="white--text font-16">Add New Collection</span>
        </div>
      </div>
    </div>
    <div class="list-scrollable">
      <v-row class="px-2 mt-2" v-if="loading">
        <v-col
          cols="12"
          :md="md"
          v-for="(item, i) in [1, 2, 3, 4, 5, 6]"
          :key="i"
        >
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
      <div class="list-content mt-2" v-if="!loading">
        <v-row class="px-2">
          <v-col cols="12" :md="md" v-for="(item, i) in listItems" :key="i">
            <div
              class="item b-cover pa-2 flex-column d-flex justify-space-between cursor-pointer"
            >
              <div @click="$emit('itemClicked', item)">
                <div class="item-img img-cover">
                  <img
                      width="100%"
                      height="180"
                      class="object-cover"
                      v-if="item.coverImage"
                      :src="item.coverImage.url"
                      alt=""
                    />
                    <img
                      height="180"
                      class="w-100 object-cover"
                      v-else
                      src="http://ncs.cd.gov.mn/wp-content/themes/icetheme/assets/images/no-image-small.png"
                      alt=""
                    />
                </div>
                <div class="pb-2 px-3">
                  <span
                    class="main-title d-block my-2 secondary--text transition-3s font-15"
                    >{{ item.title }}</span
                  >

                  <p class="black-grey--text mb-0 font-15" v-if="item.body">
                    {{
                      item.body.slice(0, 100) +
                      (item.body.length > 100 ? "..." : "")
                    }}
                  </p>
                  <span class="black-grey--text mb-0 font-13 mt-3 d-block">
                    {{ item.type }}
                  </span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="pt-2 pb-4 pagination-count text-center" v-if="total > 12">
      <v-pagination
        v-model="page"
        @input="paginationHandler"
        :length="totalFilter"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listItems: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    isDeleted: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    },
    districts: {
      type: Array,
      default: () => []
    },
    md: {
      type: Number,
      default: 6
    },
    canAddCollection: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({ page: 1 }),
  computed: {
    totalFilter() {
      return Math.ceil(+(this.total / 12));
    }
  },
  methods: {
    reloadCollectionData() {
      this.$emit("reloadData");
    },
    async collectionSearch(text) {
      this.$emit("searchCollection", text);
    },
    paginationHandler(event) {
      this.$emit("paginationHandler", event);
    }
  }
};
</script>

<style lang="scss" scoped>
.collections-list {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .list-scrollable {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
  }
  .list-content {
    .item {
      background: white;
      border-radius: 5px;
      box-shadow: 3px 3px 5px rgb(0 0 0 / 10%);
      overflow: hidden;
      white-space: pre-wrap;
      padding: 10px;

      .item-img {
        height: 150px;
        max-height: 200px;
        overflow: hidden;
        width: 100%;
        div {
          height: 100%;
        }
        img {
          height: 100%;
          object-fit: cover;
          transition: 0.3s;
        }
      }
      p {
        white-space: normal;
      }

      &:hover {
        .item-img {
          img {
            transform: scale(1.1);
          }
        }
        .main-title {
          color: $secondary !important;
        }
      }
    }
  }
}
</style>
