<template>
  <div class="collections-body py-5" v-if="item">
    <div class="pb-2 d-flex" v-if="!viewOnly">
      <v-icon
        size="40"
        class="cursor-pointer ml-auto"
        @click="collapse"
        color="black"
        >mdi-progress-close</v-icon
      >
    </div>

    <div class="collections-body-content">
      <div class="main-item pt-3">
        <div class="main-img">
          <img
            v-if="item.data && item.data.coverImage"
            width="100%"
            height="100%"
            class="object-cover cursor-pointer"
            :src="item.data.coverImage.url"
            @click="photosDialogHandler"
          />
        </div>
      </div>
      <v-row :key="item.data._id">
        <v-col cols="12" :md="12">
          <div class="mt-5">
            <div class="main-title d-flex align-center justify-space-between">
              <span
                class="main-title d-block my-2 black--text transition-3s font-20 font-500"
                >{{ item.data.title[$root.language] }}</span
              >
              <div class="d-flex" v-if="!viewOnly">
                <div
                  v-if="actions.includes('delete')"
                  class="icon-cover mr-1 cursor-pointer base-btn"
                  @click="deleteConfirm"
                >
                  <v-icon color="error">mdi-delete</v-icon>
                </div>
                <div
                  v-if="actions.includes('edit')"
                  class="icon-cover mr-1 cursor-pointer base-btn"
                  @click="editHandler"
                >
                  <v-icon color="black-grey">mdi-pencil</v-icon>
                </div>
              </div>
            </div>
            <p class="black--text font-15 mt-3">
              {{ item.data.header[$root.language] }}
            </p>
            <p class="gray--text font-14">
              {{ item.data.body[$root.language] }}
            </p>
          </div>
          <div class="line mb-7"></div>

          <div
            v-for="(attraction, i) in item.data[item.data.type + 's']"
            :key="i"
          >
            <div class="item mb-3">
              <div class="item-cover pb-3">
                <div class="item-img radius-5">
                  <img
                    v-if="attraction.coverImage"
                    :src="attraction.coverImage.url"
                    alt=""
                  />
                </div>
                <div class="pb-0 px-3">
                  <span
                    class="main-title d-block mt-2 black--text transition-3s font-18 font-500"
                    >{{ attraction.name[$root.language] }} / {{ item.data.type }}
                  </span>
                  <div
                    class="d-flex align-center justify-space-between mt-2"
                    v-if="attraction.location"
                  >
                    <span class="black-grey--text font-14"
                      >{{ attraction.location.district.country }} /
                      {{ attraction.location.district.name[$root.language] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <p class="black-grey--text font-14 mt-2" v-if="attraction.body">
              {{ attraction.body[$root.language] }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="deleteDialog" width="300">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
      ></delete-confirm>
    </v-dialog>
    <v-dialog
      v-model="photosDialog"
      fullscreen
      hide-overlay
      transition="scale-transition"
      content-class="full-dialog"
    >
      <dialog-photos
        :key="photosDialog"
        :images="images"
        @close="photosDialog = false"
      ></dialog-photos>
    </v-dialog>
  </div>
  <div v-else style="height: 100vh" class="pt-5">
    <v-skeleton-loader type="card"></v-skeleton-loader>
    <v-col cols="12" md="12" v-for="(item, i) in [1, 2, 3, 4, 5, 6]" :key="i">
      <v-skeleton-loader type="text"></v-skeleton-loader>
    </v-col>
  </div>
</template>

<script>
import DialogPhotos from "@/components/dialogs/Photos";
export default {
  props: {
    item: {
      type: [Object],
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => ["delete", "edit"]
    }
  },
  components: { DialogPhotos },
  data: () => ({
    photosDialog: false,
    images: [],
    deleteDialog: false
  }),
  methods: {
    viewItem() {
      this.editDialog = true;
    },
    deleteConfirm() {
      this.deleteDialog = true;
    },
    async deleteHandler() {
      const res = await this.$axios.delete(
        `/collections/${this.item.data._id}`
      );

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success"
        });
        this.deleteDialog = false;
        this.$emit("reloadData");
        this.$emit("collapse");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    },
    editHandler() {
      this.$emit("editCollection");
    },
    photosDialogHandler() {
      this.images = { url: this.item.data.coverImage };
      this.photosDialog = true;
    },
    collapse() {
      this.$emit("collapse");
    }
  },
  async created() {}
};
</script>

<style lang="scss" scoped>
.collections-body {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .collections-body-content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;

    .main-item {
      img {
        border-radius: 5px;
        overflow: hidden;
        height: 300px;
      }
      .main-title {
        .icon-cover {
          border-radius: 3px;
          background: $graybg;
          padding: 4px;
          border: 1px solid $grayicon;
          transition: 0.2s;
          &:hover {
            background: #f3f3f3;
          }
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: $grayicon;
    }

    .item {
      white-space: pre-wrap;
      padding: 7px;
      border: 1px solid rgba($grayicon, 0.4);
      border-radius: 5px;
      .item-cover {
        overflow: hidden;

        .item-img {
          height: 200px;
          max-height: 200px;
          overflow: hidden;
          width: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s;
          }
        }

        &:hover {
          .item-img {
            img {
              transform: scale(1.1);
            }
          }
          .main-title {
            color: $secondary !important;
          }
        }
      }
    }
  }
}
</style>
