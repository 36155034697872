<template>
  <div class="collection-form-step overflow-x-h">
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <div class="collection-form-step-head py-3">
      <span class="black--text font-16 font-500"
        >2 of 2 – Add collection details
      </span>
    </div>

    <div class="collection-form-step-content py-9">
      <v-form v-model="valid">
        <!-- select list -->
        <v-row
          justify="center"
          v-for="(select, i) in obj['miniguides']"
          :key="i"
        >
          <v-col cols="12" md="2" class="text-center">
            <div class="base-btn" @click="removeHandler(select)">
              <v-icon color="error">mdi-minus-circle-outline</v-icon>
            </div>
          </v-col>
          <v-col cols="12" md="7">
            <v-autocomplete
              :items="selects"
              item-text="name"
              outlined
              dense
              class="base-select"
              :hide-details="true"
              v-model="select._id"
              @input="select.value = true"
              item-value="_id"
              :placeholder="`Select Attraction`"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- description -->

        <v-col cols="12" md="2" justify="center" class="mt-2 text-center">
          <div class="base-btn" @click="addNewHandler">
            <v-icon>mdi-plus-circle-outline</v-icon>
          </div>
        </v-col>
        <v-col cols="12" class="mt-14">
          <!-- buttons -->
          <buttons-experience
            @cancel="$emit('cancel')"
            @next="submitHandler"
            text="Submit"
            :isValid="valid"
            :key="valid"
          ></buttons-experience>
        </v-col>
      </v-form>
    </div>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  components: {
    ButtonsExperience
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    villages: [],
    miniguides: [],
    selects: [],
    obj: {},
    valid: false,
    language: "en"
  }),
  methods: {
    addNewHandler() {
      this.obj["miniguides"].push({ value: false, _id: Math.random() });
    },
    removeHandler(desc) {
      this.obj["miniguides"] = [
        ...this.obj["miniguides"].filter((i) => i._id != desc._id)
      ];
    },

    // send value of array to parent
    submitHandler() {
      // check if array is empty
      let isArrEmpty = this.obj["miniguides"].filter(
        (idx) => idx.value == false
      );
      if (this.obj["miniguides"].length == 0 || isArrEmpty.length > 0) {
        this.$store.dispatch("showSnack", {
          text: "must not be empty",
          color: "error"
        });
        return;
      }
      this.obj["miniguides"] = this.obj["miniguides"].filter(
        (value, index, self) =>
          index === self.findIndex((t) => t._id === value._id)
      );
      this.$emit("submit", this.obj["miniguides"]);
    },

    async fetchVillages() {
      let { data } = await this.$axios.get("activity/all/selectors");
      if (data) {
        this.villages = data.villages;
        this.miniguides = data.miniguides;
        this.selects = this["miniguides"];
      }
    }
  },
  async created() {
    this.language = this.$root.language;
    await this.fetchVillages();
    if (this.isEdit) {
      this.obj = { ...this.item };
    } else {
      this.obj = {
        type: "miniguide",
        villages: [],
        miniguides: []
      };
    }

    if (this.obj["miniguides"].length == 0) {
      this.obj["miniguides"].push({ value: false, _id: Math.random() });
    }
  }
};
</script>

<style></style>
