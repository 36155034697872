<template>
  <div class="collections">
    <v-row class="ma-0 pa-0">
      <v-col
        cols="12"
        :md="paramID ? 6 : 12"
        v-if="!$vuetify.breakpoint.mobile || !paramID"
      >
        <list-items
          :listItems="listItems"
          @paginationHandler="fetchList"
          :total="total"
          :districts="districts"
          :loading="loading"
          @searchCollection="searchCollection"
          @reloadData="reloadData"
          @addCollection="addCollection"
          :md="paramID ? 6 : 3"
          @itemClicked="itemClicked"
        ></list-items>
      </v-col>
      <v-col cols="12" :md="6">
        <item-body
          v-if="itemID && itemID._id"
          :item="{data: itemID}"
          @editCollection="editCollection"
          @reloadData="reloadData"
          @collapse="collapse"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="addCollectionDialog" max-width="750px">
      <new-collection-dialog
        :key="addCollectionDialog"
        @close="addCollectionDialog = false"
        @reloadData="reloadData"
      ></new-collection-dialog>
    </v-dialog>
    <v-dialog v-model="editCollectionDialog" max-width="750px">
      <new-collection-dialog
        v-if="itemID"
        :key="editCollectionDialog"
        @close="editCollectionDialog = false"
        @reloadData="reloadData"
        :item="itemID"
        :isEdit="true"
      ></new-collection-dialog>
    </v-dialog>
  </div>
</template>

<script>
import ListItems from "@/components/collections/ListItems";
import ItemBody from "@/components/collections/ItemBody";
import NewCollectionDialog from "@/components/dialogs/collection/Index.vue";
export default {
  components: {
    ListItems,
    ItemBody,
    NewCollectionDialog
  },
  data: () => ({
    page: 1,
    paramID: "",
    listItems: [],
    itemID: undefined,
    total: 0,
    districts: [],
    loading: true,
    searchWord: undefined,
    editCollectionDialog: false,
    addCollectionDialog: false
  }),
  watch: {
    "$route.params": {
      async handler() {
        this.paramID = this.$route.params.id;
        await this.fetchItemByID();
      }
    }
  },
  methods: {
    reloadData() {
      this.fetchList(this.page);
      this.fetchItemByID();
    },
    addCollection() {
      this.addCollectionDialog = true;
    },
    editCollection() {
      this.editCollectionDialog = true;
    },
    async searchCollection(searchWord) {
      this.listItems = [];
      this.page = 1;
      this.searchWord = searchWord;
      await this.fetchList();
      if (!this.$vuetify.breakpoint.mobile) {
        if (!searchWord || searchWord.length == 0) {
          this.paramID = this.$route.params.id;
          this.fetchItemByID();
        } else if (this.listItems.length > 0) {
          this.paramID = this.listItems[0]._id;
          this.fetchItemByID();
        } else {
          this.paramID = undefined;
          this.itemID = undefined;
        }
      }
    },
    async fetchList(page) {
      this.loading = true;
      if (this.source) {
        this.source.cancel();
      }
      this.source = this.$axios.CancelToken.source();
      const currentSource = this.source;
      if (!page) {
        page = 1;
      }
      this.page = page;
      let { data } = await this.$axios.get(
        `/collections/all/new?${
          (this.searchWord && this.searchWord.length) > 0
            ? "searchWord=" + this.searchWord + "&"
            : ""
        }pageSize=12&pageNumber=${page}`,
        { cancelToken: this.source.token }
      );
      if (data) {
        this.listItems = data.data;
        this.total = data.count;
      }
      if (currentSource === this.source) {
        this.loading = false;
      }
    },
    async fetchItemByID() {
      if (this.paramID) {
        let { data } = await this.$axios.get(`/collections/${this.paramID}`);

        if (data && data.data && data.data.length > 0) {
          this.itemID = data.data[0];
        }
      }
    },
    collapse() {
      this.$router.push("/collections");
      this.paramID = undefined
    },
    itemClicked(item) {
      this.$router.push(`/collections/${item._id}`)
    }
  },
  async created() {
    this.paramID = this.$route.params.id;
    await this.fetchList();
    await this.fetchItemByID();
  }
};
</script>

<style lang="scss" scoped>
.collections {
  background: white;
  height: 100vh;
  overflow: hidden;
}
</style>
